.chatRoomContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.time {
  font-size: 8pt;
  margin: 2px;
}

.chatRoomInput {
  position: relative;
  text-align: center;
  border: 2px solid black;
  width: 50%;
  height: 70vh;
  overflow: auto;
  margin: 15px;
  border-radius: 25px;
  background: white;
}

.userHasJoined {
  background: rgb(172, 58, 48);
  color: white;
  padding-left: 5px;
}

.chatRoomForm,
.chatMessage {
  background: none;
  display: flex;
}

.chatMessage {
  display: flex;
  flex-direction: row-reverse;
}

.foreignChatMessage {
  display: flex;
  flex-direction: row;
  background: none;
}

.currentMessage,
.foreignMessage {
  padding: 5px;
  padding-left: 10px;
  border: 1px solid black;
  border-radius: 12px; /* one half of ( (border * 2) + height + padding ) */
  box-shadow: 2px 2px 2px darkgray;
  font: 12px Helvetica, Verdana, Tahoma;
  text-align: left;
  width: 40%;
  line-height: 20px;
  margin: 5px;
}

.currentMessage {
  background: -webkit-radial-gradient(5px -9px, circle, white 4%, #5ca0d3 26px);
  color: white;
}

.foreignMessage {
  background: -webkit-radial-gradient(
    5px -9px,
    circle,
    white 4%,
    lightgrey 26px
  );
  color: black;
}

.sendMessage {
  margin: 5px;
  padding: 10px;
  border-radius: 15px;
}

.sendMessage:focus {
  outline: none;
}
.currentUser,
.foreignUser {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  font-weight: bolder;
  font-size: 15px;
  line-height: 200%;
  text-align: center;
  background: #d94032;
  margin: 5px;
}

.messages {
  display: flex;
  flex-direction: column;
  background: #c7cfd9;
  height: 60vh;
  overflow: auto;
  border-radius: 2px;
}

button.chatSendButton {
  margin: 10px;
  background: #44682f;
  color: white;
  border: none;
}
button.chatSendButton:active {
  transform: translateY(1px);
}
button.backButton {
  background-color: rgb(172, 58, 48);
  padding: 3px;
  width: 5em;
  cursor: pointer;
  font-weight: bold;
  border-radius: 35em;
  border: none;
  color: white;
}

button.backButton:hover::before {
  content: "\00ab";
  opacity: 1;
  left: 0;
  transition: all 0.5s;
  padding-right: 2px;
}

@media only screen and (max-width: 504px) {
  .sendMessage {
    width: 40%;
  }

  .chatRoomForm button {
    margin: 10px;
  }
}
