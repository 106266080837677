* body {
  background: rgb(242, 245, 248);
  /* font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode",
    "Lucida Sans", Tahoma, sans-serif; */
    font-family: 'Work Sans', sans-serif;
  color: rgb(54, 49, 43);
}

.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
main{
  margin-top: 23px;
}
.row {
  max-width: 1200px;
  padding-left: auto;
  padding-right: auto;
  margin: 0px auto 10px auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.row::before,
.row::after {
  display: table;
  content: "";
}

.row::after {
  clear: both;
}

.col-3,
.col-4,
.col-6,
.col-12 {
  padding-left: 1.04166666%;
  padding-right: 1.04166666%;
  display: inline;
}

.col-4,
.col-6,
.col-12 {
  min-height: 100%;
}
.col-12{
  width: 95%;
}
.col-6{
  width: 85%;
}
.col-3 {
  width: 65%;
}

@media only screen and (min-width: 630px) {
  .col-3 {
    width: 26%;
  }

  .col-4 {
    width: 33.333333%;
  }

  .col-6 {
    width: 50%;
  }

  .col-12 {
    width: 100%;
  }
}

@media only screen and (max-width: 630px) {
  .col-3{
    margin-left: 30%;
    margin-right: 30%;
  }
}
  
  @media only screen and (max-width: 500px) {
    .col-3{
      margin-left: auto;
      margin-right: auto;
    }
  }
