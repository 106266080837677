header{
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  margin-right: auto;
  margin-left: auto;
  z-index:1000;
  background: #002C4D;
}
span.brandQQ{
  color: #1DE5FF;
}
nav[role="navigation"].row{
  margin-bottom: 0px;
  max-width: 1250px;
}
nav {
  display: flex;
  margin-bottom: 0px;
  font-size: 14px;
  padding-bottom: 1px;
  text-decoration: none;
}

.nav-text-right,
.nav-text,
.fas {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  font-size: 15px;
}
.nav-text-right,
.nav-text{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.Navbar-right,
.Navbar-right-no-user,
.nav-text {
  display: flex;
  margin-left: auto;
  margin-right: 2em;
}

.nav-text,
.nav-text-right {
  color: #ffffff;
  text-decoration: none;
}
.nav-text-right:hover,
.Navbar-toggle:hover,.Navbar-ToggleShow:hover {
  color: #d83f31;
}
.login {
  padding-right: 10px;
}
.student-history-nav::after,
.messageViaSlack::after,
.data-link::after,
.userName::after {
  content: " | ";
  margin-right: 4px;
}
.brand {
  margin-left: 2em;
  margin-right: auto;
}

@media only screen and (max-width: 540px) {
  nav,
  .Navbar-right {
    flex-direction: column;
  }
  .nav-text-right {
    display: none;
  }
  
}
