h3{
  text-align: center;
}
.mentorWrapper{
  display: grid;
  grid-template-areas: 
    "title title"
    "sidebar1 list"
    "sidebar1 list" 
    "sidebar3 list";
  grid-gap: 5px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  background: rgb(214, 234, 252);
  padding: .2em 1em 1em 1em;
  border-radius: 2px;
  margin-top: 1em;
  margin-left: 5px;
  margin-right: 5px;
  width: 90%;
}
.mentorTitle{
  display: flex;
  grid-area: title;
  justify-content: center;
}
.mentorList ul{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.listSelection{
 grid-area: sidebar1;
 border-radius: 2px;
 box-shadow:  1px 0px 0px 0px rgba(0, 0, 0, 0.2), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
 background: #ffffff;
}
.listSelection div{
  margin: 1em .5em;

}
.listSelection div:hover{
  color: red;
  cursor: pointer;
}
.mentorList{
  grid-area: list;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.2), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
}
.titleSection:hover{
  background: rgb(158, 156, 156);;
}
.studentTitle,
.studentName,
.mentorName,
.being-helped-section-title,
.has-been-helped-title {
  display:flex;
  flex-direction: column;
  margin: 10px;
  width: max-content;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1);
  font-weight: normal;
}
.studentName,
.studentTitle {
  color: #000000;
  background: #d83f31;
}
.has-been-helped-title {
  background: rgb(33, 94, 33);
  color: rgb(223, 209, 209);
}