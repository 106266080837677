.messageContainer {
  display: inline;
}

#sendMessage {
  background: white;
}
.studentDropdown {
  height: 30px;
  width: 150px;
  margin: 20px;
  font-weight: bold;
  color: white;
  background: #D83F31;
}
.message-textBox {
  width: 98%;
  align-content: center;
}
.slack-message-button {
  display: flex;
  margin-top: 10px;
  margin-left: auto;
}
.slack-message-title{
  text-align: center;
  margin-top: 5px;
}