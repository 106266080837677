.studentInfo {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.data-lists {
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  padding: 20px;
  border-radius: 7px;
  overflow: auto;
  background-color: #c7cfd9;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3),
    1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.data-lists li {
  border-bottom: 1px solid #5e0a0a;
  padding: 8px;
  margin: 5px;
}

.studentInfo h2 {
  padding-top: 50px;
  color: #5e0a0a;
  font-weight: bolder;
}
