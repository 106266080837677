body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svg-container{
  background: url(/static/media/study.35a52606.jpg);
  background-size:cover;
}
section.lands.row{
  display: block;
}
.application-features{
  display: flex;
}
.landing-info{
  color: #002C4D;
}
.landing-container{
  background: -webkit-gradient(linear ,left top, left bottom ,from(#80D0C7) ,to(#0192E6) );
  background: linear-gradient(#80D0C7 ,#0192E6 );
}
img.col-4{
  display: block;
  margin: auto;
}
.start-btn{
  text-decoration: none;
}
.startedBtn{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top:0;
  background: #27603A;
  border: none;
  color: white;
  padding: 4px;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 4px;
  font-family: 'Work Sans', sans-serif;
}
.lands.row{
  margin-bottom: 0;
  color: #ffffff;
  text-shadow: 0 3px 6px #0000009c
 }
 svg{
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
 }
 h3.app-text{
   text-align: center;
   color: #002C4D;
   font-family: 'Roboto', sans-serif;
   font-weight: bold;
 }
 h2.land{
   font-weight: 400;
 }
.svg-wave{
  fill:#0192E6;
}
.land{
  margin: 0;
  padding-top: 1em;
    text-align: center;
}
.landing-para.col-6{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
div img.col-4{
  width: 40%;
  fill: #002C4D;
}

div.footer-container{
  display: flex;
}
.contribution-container{
  display: flex;
  margin-left: auto;
}
h3.contribution{
  margin-top: auto;
  margin-bottom: auto;
}
footer{
  background-color: #002C4D;
  color:#f1ebeb;
}
ul{
  list-style: none;
}
footer ul {
  padding-left: 6px;
  padding-right: 1em;
}

h3.col-6{
  display: none;
}
a.github{
  text-decoration: none;
  color: #1DE5FF;
}
div.footer-container.row{
  margin-bottom: 0;
}
@media only screen and (min-width: 630px){
  h3.col-6{
    display: inline;
    margin: auto;
  }
}


.loginContainer {
  text-align: center;
}

.loginInput, .demoAccountInfo {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 15px 10px;
  border-radius: 7px;
  background: #c7cfd9;
  font-family: "Montserrat", sans-serif;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3),
    1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}
img[alt="spinning loader"]{
  display: flex;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
.loginInput{
  margin-bottom: .8em;
}

.passwordInput,
.usernameInput,
.fullNameInput {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}

.usernameInput {
  margin-top: 10px;
}

.loginTitle {
  color: #d94032;
  font-family: "Karla", sans-serif;
  font-size: 2.2em;
}
.demoAccountInfo{
  flex-direction: column;
  font-size: 14px;
}
.demoInfo{
  margin-top:0px;
  margin: 0 auto 5px 0;
  text-align: left;
}
.loginLinks{
  display: flex;
  flex-direction: row;
  margin: 10px auto 10px auto;
}
.demoTitle{
  margin: 5px auto 5px auto;
}
.loginButton{
  margin-right: .4em;
}
.loginButton,
.registrationSubmit {
  font-family: "Montserrat", sans-serif;
  /* display: inline-block; */
  color: white;
  padding: 4px;
  border: none;
  background: rgba(206, 45, 30, 0.808);
  font-weight: bold;
  border-radius: 1.2em;
  /* width: 30%; */
  /* margin-right: 9px; */
}
.loginButton:active,
.registrationSubmit:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.loginButton:hover,
.registrationSubmit:hover {
  background: rgba(143, 31, 21, 0.808);
}
input[type="text"],
input[type="password"] {
  flex: 1 1;
}

@media only screen and (max-width: 600px) {
}

.registrationContainer {
  text-align: center;
}
.register-text {
  text-align: center;
  color: rgb(134, 140, 146);
}
.registrationInput {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  text-align: center;
  border-radius: 7px;
  width: 100%;
  background: #c7cfd9;
  padding-left: 1.4px;
  padding-right: 1.4px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3),
    1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

input {
  margin-top: 3px;
  background: white;
  border: 1px solid #403931;
  padding: 5px;
}

.title-selection {
  display: inline-block;
  margin-bottom: 1px;
}

.registerTitle {
  color: #d94032;
  text-align: center;
  font-family: "Karla", sans-serif;
  font-size: 2.4em;
}

.alreadyHaveAccount {
  color: black;
  margin-bottom: 5px;
}

.loginLink {
  color: #5e0a0a;
}

@media only screen and (max-width: 600px) {
}

.Navbar-ToggleShow{
    display: flex;
  color: #ffffff;
  text-decoration: none;
  margin-left: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.Navbar-ToggleShow.signUp{
  margin-bottom: .2em;
}
.Navbar-toggle {
  display: none;
  color: #ffffff;
}
.Navbar-toggle:active {
  -webkit-transform: translateY(.8px);
          transform: translateY(.8px);
}
.Navbar-ToggleShow.logout{
  padding-right: 4px;
}
.Navbar-ToggleShow.student-history-nav::after,
.Navbar-ToggleShow.messageViaSlack::after,
.Navbar-ToggleShow.data-link::after,
.Navbar-ToggleShow.userName::after {
  content: "";
}

@media only screen and (min-width: 540px){
    .Navbar-right-mobile{
      display: none;
    }
  }
@media only screen and (max-width: 540px) {
    .Navbar-toggle {
      align-self: flex-end;
      display: inline;
      display: initial;
      position: absolute;
      cursor: pointer;
      top: .1em;
      right: 2.1em;
    }
    
    .Navbar-right-mobile{
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: 2em;
    }
}
header{
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  margin-right: auto;
  margin-left: auto;
  z-index:1000;
  background: #002C4D;
}
span.brandQQ{
  color: #1DE5FF;
}
nav[role="navigation"].row{
  margin-bottom: 0px;
  max-width: 1250px;
}
nav {
  display: flex;
  margin-bottom: 0px;
  font-size: 14px;
  padding-bottom: 1px;
  text-decoration: none;
}

.nav-text-right,
.nav-text,
.fas {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  font-size: 15px;
}
.nav-text-right,
.nav-text{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.Navbar-right,
.Navbar-right-no-user,
.nav-text {
  display: flex;
  margin-left: auto;
  margin-right: 2em;
}

.nav-text,
.nav-text-right {
  color: #ffffff;
  text-decoration: none;
}
.nav-text-right:hover,
.Navbar-toggle:hover,.Navbar-ToggleShow:hover {
  color: #d83f31;
}
.login {
  padding-right: 10px;
}
.student-history-nav::after,
.messageViaSlack::after,
.data-link::after,
.userName::after {
  content: " | ";
  margin-right: 4px;
}
.brand {
  margin-left: 2em;
  margin-right: auto;
}

@media only screen and (max-width: 540px) {
  nav,
  .Navbar-right {
    flex-direction: column;
  }
  .nav-text-right {
    display: none;
  }
  
}

.notFound {
  text-align: center;
}

.mentorName {
  background: #BFB99E;
}
.listSection{
    flex-direction: column;
}
ul{
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
    -webkit-margin-after: 0em;
            margin-block-end: 0em;
}
.studentName span:hover{
  cursor: pointer;
  font-weight: bold;
}
.studentName.helped{
  background: rgb(96, 138, 96);
}

/* 
old animations had weird side effects so re worked it
feel free to change to another animation so long as there are no side effects to the structure of the page
*/

.Has-Been-Helped-List-Section ul,
.Has-Been-Helped-List-Section li,
.Being-Helped-List-Section ul,
.Waiting-List-Section ul {
  -webkit-animation: lower 1.5s cubic-bezier(0.15, 0.8, .3, 1) both;
	        animation: lower 1.5s cubic-bezier(0.15, 0.8, .3, 1) both;
}

@-webkit-keyframes lower {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes lower {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
.Button {
  margin: 0.8em;
  border: 1px solid #5ca0d3;
  border-radius: 35em;
}

.Button:hover {
  cursor: pointer;
}
h3{
  text-align: center;
}
.mentorWrapper{
  display: grid;
  grid-template-areas: 
    "title title"
    "sidebar1 list"
    "sidebar1 list" 
    "sidebar3 list";
  grid-gap: 5px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  background: rgb(214, 234, 252);
  padding: .2em 1em 1em 1em;
  border-radius: 2px;
  margin-top: 1em;
  margin-left: 5px;
  margin-right: 5px;
  width: 90%;
}
.mentorTitle{
  display: flex;
  grid-area: title;
  justify-content: center;
}
.mentorList ul{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.listSelection{
 grid-area: sidebar1;
 border-radius: 2px;
 box-shadow:  1px 0px 0px 0px rgba(0, 0, 0, 0.2), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
 background: #ffffff;
}
.listSelection div{
  margin: 1em .5em;

}
.listSelection div:hover{
  color: red;
  cursor: pointer;
}
.mentorList{
  grid-area: list;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.2), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
}
.titleSection:hover{
  background: rgb(158, 156, 156);;
}
.studentTitle,
.studentName,
.mentorName,
.being-helped-section-title,
.has-been-helped-title {
  display:flex;
  flex-direction: column;
  margin: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1);
  font-weight: normal;
}
.studentName,
.studentTitle {
  color: #000000;
  background: #d83f31;
}
.has-been-helped-title {
  background: rgb(33, 94, 33);
  color: rgb(223, 209, 209);
}
.messageContainer {
  display: inline;
}

#sendMessage {
  background: white;
}
.studentDropdown {
  height: 30px;
  width: 150px;
  margin: 20px;
  font-weight: bold;
  color: white;
  background: #D83F31;
}
.message-textBox {
  width: 98%;
  align-content: center;
}
.slack-message-button {
  display: flex;
  margin-top: 10px;
  margin-left: auto;
}
.slack-message-title{
  text-align: center;
  margin-top: 5px;
}
.getHelpButton:hover {
  cursor: pointer;
  background: rgb(228, 248, 255);
  color: black;
  border: 1px solid black;
}

.submitButton:hover {
  cursor: pointer;
  background: rgb(163, 228, 163);
  color: black;
  border: 1px solid black;
}

.studentsMainPage {
  text-align: center;
  font-weight: bold;
}
.student-dashboard-container {
  font-family: "Montserrat", sans-serif;
}
.studentWaitingQueue {
  display: inline-block;
  padding: 8px;
  overflow: auto;
  list-style: none;
  height: 100%;
  border: 2px solid #5f54478a;
  text-align: center;
  background: rgba(221, 219, 217, 0.596);
  border-radius: 3px;
}

.noOneInQueue {
  display: block;
}

.studentListTitle {
  color: #d94032;
  font-family: "Karla", sans-serif;
  font-size: 2em;
  margin-bottom: 1em;
}

.studentNameForQueue {
  display: grid;
  margin: 10px;
  padding: 8px;
  color: #000000;
  background: rgba(191, 185, 158, 0.767);
  font-weight: normal;
  border-radius: 8px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1);
}
.position-in-line {
  margin-bottom: 5px;
}
.tooltiptext,
.select {
  border: none;
  outline: none;
  background-color: inherit;
}

.select:hover {
  cursor: pointer;
}


.studentDataListTitles{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left:0px;
}
.data-student-name{
  margin-right: 10px;
  color: black;
  margin-bottom: 10px;
}
.data-question{
  color: rgb(204, 89, 79);
}
.display-data-section{
  margin-top: 2.3em;
}
.display-data-section a {
  text-decoration: none;
  color: black;
}

.chartjs-render-monitor {
  margin-bottom: 25px;
  width: 80%;
}

.display-data-section a:hover {
  color: #403931;
}


.display-data-section ul {
  list-style: none;
}


.dataPageContainer{
  padding-top: 20px;
  padding-left:13px;
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow: auto;
  border-radius: 7px;
  background: #c7cfd9;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3), 1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  color: black;
  margin-bottom: 14px;
}
.graphTitle{
  font-size: 18px;
  text-align: center;
}

.chatRoomContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.time {
  font-size: 8pt;
  margin: 2px;
}

.chatRoomInput {
  position: relative;
  text-align: center;
  border: 2px solid black;
  width: 50%;
  height: 70vh;
  overflow: auto;
  margin: 15px;
  border-radius: 25px;
  background: white;
}

.userHasJoined {
  background: rgb(172, 58, 48);
  color: white;
  padding-left: 5px;
}

.chatRoomForm,
.chatMessage {
  background: none;
  display: flex;
}

.chatMessage {
  display: flex;
  flex-direction: row-reverse;
}

.foreignChatMessage {
  display: flex;
  flex-direction: row;
  background: none;
}

.currentMessage,
.foreignMessage {
  padding: 5px;
  padding-left: 10px;
  border: 1px solid black;
  border-radius: 12px; /* one half of ( (border * 2) + height + padding ) */
  box-shadow: 2px 2px 2px darkgray;
  font: 12px Helvetica, Verdana, Tahoma;
  text-align: left;
  width: 40%;
  line-height: 20px;
  margin: 5px;
}

.currentMessage {
  background: -webkit-radial-gradient(5px -9px, circle, white 4%, #5ca0d3 26px);
  color: white;
}

.foreignMessage {
  background: -webkit-radial-gradient(
    5px -9px,
    circle,
    white 4%,
    lightgrey 26px
  );
  color: black;
}

.sendMessage {
  margin: 5px;
  padding: 10px;
  border-radius: 15px;
}

.sendMessage:focus {
  outline: none;
}
.currentUser,
.foreignUser {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  font-weight: bolder;
  font-size: 15px;
  line-height: 200%;
  text-align: center;
  background: #d94032;
  margin: 5px;
}

.messages {
  display: flex;
  flex-direction: column;
  background: #c7cfd9;
  height: 60vh;
  overflow: auto;
  border-radius: 2px;
}

button.chatSendButton {
  margin: 10px;
  background: #44682f;
  color: white;
  border: none;
}
button.chatSendButton:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
button.backButton {
  background-color: rgb(172, 58, 48);
  padding: 3px;
  width: 5em;
  cursor: pointer;
  font-weight: bold;
  border-radius: 35em;
  border: none;
  color: white;
}

button.backButton:hover::before {
  content: "\AB";
  opacity: 1;
  left: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  padding-right: 2px;
}

@media only screen and (max-width: 504px) {
  .sendMessage {
    width: 40%;
  }

  .chatRoomForm button {
    margin: 10px;
  }
}

#note-form {
  display: flex;
  flex-direction: column;
}

#note-form .Button,
#note-form .Label {
  margin-left: auto;
  margin-right: auto;
}
textarea[name="note-input"] {
  height: 30vh;
}
button.save {
  border: none;
  padding: 2px;
  width: 3em;
  background: rgb(74, 94, 153);
  color: white;
}


.history-list-container span:hover,
.history-list-container button:hover {
  cursor: pointer;
}

.history-list-container {
  font-family: "Montserrat", sans-serif;
  color:rgb(54, 49, 43);
  overflow: auto;
  border-radius: 7px;
  background: #c7cfd9;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3), 1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  color: black;
  margin-bottom: 14px;
  padding: 10px;
}

.history-list-container ul {
  list-style-type: none;
  padding-left: 0;
}

.history-list-container button {
  border: none;
  border-radius: 35em;
  outline: none;
  width: 8em;
  height: 3em;
  background-color: #d83f31;
}

.student-history-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#ticket-history-title {
  text-align: center;
  font-family: "Karla", sans-serif;
}
.studentInfo {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.data-lists {
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  padding: 20px;
  border-radius: 7px;
  overflow: auto;
  background-color: #c7cfd9;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3),
    1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.data-lists li {
  border-bottom: 1px solid #5e0a0a;
  padding: 8px;
  margin: 5px;
}

.studentInfo h2 {
  padding-top: 50px;
  color: #5e0a0a;
  font-weight: bolder;
}

* body {
  background: rgb(242, 245, 248);
  /* font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode",
    "Lucida Sans", Tahoma, sans-serif; */
    font-family: 'Work Sans', sans-serif;
  color: rgb(54, 49, 43);
}

.App {
  text-align: center;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
main{
  margin-top: 23px;
}
.row {
  max-width: 1200px;
  padding-left: auto;
  padding-right: auto;
  margin: 0px auto 10px auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.row::before,
.row::after {
  display: table;
  content: "";
}

.row::after {
  clear: both;
}

.col-3,
.col-4,
.col-6,
.col-12 {
  padding-left: 1.04166666%;
  padding-right: 1.04166666%;
  display: inline;
}

.col-4,
.col-6,
.col-12 {
  min-height: 100%;
}
.col-12{
  width: 95%;
}
.col-6{
  width: 85%;
}
.col-3 {
  width: 65%;
}

@media only screen and (min-width: 630px) {
  .col-3 {
    width: 26%;
  }

  .col-4 {
    width: 33.333333%;
  }

  .col-6 {
    width: 50%;
  }

  .col-12 {
    width: 100%;
  }
}

@media only screen and (max-width: 630px) {
  .col-3{
    margin-left: 30%;
    margin-right: 30%;
  }
}
  
  @media only screen and (max-width: 500px) {
    .col-3{
      margin-left: auto;
      margin-right: auto;
    }
  }

