
.studentDataListTitles{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left:0px;
}
.data-student-name{
  margin-right: 10px;
  color: black;
  margin-bottom: 10px;
}
.data-question{
  color: rgb(204, 89, 79);
}
.display-data-section{
  margin-top: 2.3em;
}
.display-data-section a {
  text-decoration: none;
  color: black;
}

.chartjs-render-monitor {
  margin-bottom: 25px;
  width: 80%;
}

.display-data-section a:hover {
  color: #403931;
}


.display-data-section ul {
  list-style: none;
}


.dataPageContainer{
  padding-top: 20px;
  padding-left:13px;
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow: auto;
  border-radius: 7px;
  background: #c7cfd9;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3), 1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  color: black;
  margin-bottom: 14px;
}