.registrationContainer {
  text-align: center;
}
.register-text {
  text-align: center;
  color: rgb(134, 140, 146);
}
.registrationInput {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  text-align: center;
  border-radius: 7px;
  width: 100%;
  background: #c7cfd9;
  padding-left: 1.4px;
  padding-right: 1.4px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3),
    1px 1px 6px 1px rgba(0, 0, 0, 0.2), 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

input {
  margin-top: 3px;
  background: white;
  border: 1px solid #403931;
  padding: 5px;
}

.title-selection {
  display: inline-block;
  margin-bottom: 1px;
}

.registerTitle {
  color: #d94032;
  text-align: center;
  font-family: "Karla", sans-serif;
  font-size: 2.4em;
}

.alreadyHaveAccount {
  color: black;
  margin-bottom: 5px;
}

.loginLink {
  color: #5e0a0a;
}

@media only screen and (max-width: 600px) {
}
