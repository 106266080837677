#note-form {
  display: flex;
  flex-direction: column;
}

#note-form .Button,
#note-form .Label {
  margin-left: auto;
  margin-right: auto;
}
textarea[name="note-input"] {
  height: 30vh;
}
button.save {
  border: none;
  padding: 2px;
  width: 3em;
  background: rgb(74, 94, 153);
  color: white;
}
