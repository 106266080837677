/* 
old animations had weird side effects so re worked it
feel free to change to another animation so long as there are no side effects to the structure of the page
*/

.Has-Been-Helped-List-Section ul,
.Has-Been-Helped-List-Section li,
.Being-Helped-List-Section ul,
.Waiting-List-Section ul {
  -webkit-animation: lower 1.5s cubic-bezier(0.15, 0.8, .3, 1) both;
	        animation: lower 1.5s cubic-bezier(0.15, 0.8, .3, 1) both;
}

@-webkit-keyframes lower {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes lower {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}