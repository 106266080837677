.studentsMainPage {
  text-align: center;
  font-weight: bold;
}
.student-dashboard-container {
  font-family: "Montserrat", sans-serif;
}
.studentWaitingQueue {
  display: inline-block;
  padding: 8px;
  overflow: auto;
  list-style: none;
  height: 100%;
  border: 2px solid #5f54478a;
  text-align: center;
  background: rgba(221, 219, 217, 0.596);
  border-radius: 3px;
}

.noOneInQueue {
  display: block;
}

.studentListTitle {
  color: #d94032;
  font-family: "Karla", sans-serif;
  font-size: 2em;
  margin-bottom: 1em;
}

.studentNameForQueue {
  display: grid;
  margin: 10px;
  padding: 8px;
  color: #000000;
  background: rgba(191, 185, 158, 0.767);
  font-weight: normal;
  border-radius: 8px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1), 1px 1px 4px 2px rgba(0, 0, 0, 0.1),
    1px 1px 4px 2px rgba(0, 0, 0, 0.1);
}
.position-in-line {
  margin-bottom: 5px;
}
.tooltiptext,
.select {
  border: none;
  outline: none;
  background-color: inherit;
}

.select:hover {
  cursor: pointer;
}
