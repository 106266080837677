.mentorName {
  background: #BFB99E;
}
.listSection{
    flex-direction: column;
}
ul{
  padding-inline-start: 0;
  margin-block-start: 0em;
    margin-block-end: 0em;
}
.studentName span:hover{
  cursor: pointer;
  font-weight: bold;
}
.studentName.helped{
  background: rgb(96, 138, 96);
}
